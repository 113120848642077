
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { ProductForm } from '@/interfaces/components/configurator/ProductForm';
import ChecklistSegmentModule from '@/components/views/productView/ChecklistSegmentModule.vue';
import PriceModule from '@/components/views/project/PriceModule.vue';
import { PriceItem } from '@/interfaces/general/PriceItem';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { PriceInputObject } from '@/interfaces/components/configurator/PriceInputObject';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ConfiguratorValue } from '@/interfaces/components/configurator/ConfiguratorValue';
import { extractDefaultValues, extractUpdateFunctions } from '@/helpers/FormDataHelper';
import { debounce } from 'vue-debounce';
import { determineFieldVisibility, extractUpdateFunctionValues } from '@/helpers/FieldFunctionHelper';
import { UpdateFunctionEntry } from '@/interfaces/components/configurator/UpdateFunctionEntry';
import { ChecklistField } from '@/interfaces/components/configurator/ChecklistField';
import JsFunctionRepository from '@/repositories/JsFunctionRepository';
import JsFunction from '@/models/JsFunction';
import { parseAndTransformStringWithDynamicValues, registerJsonLogicFunctions } from '@/helpers/JsonLogicHelper';
import ClientRepository from '@/repositories/ClientRepository';
import { UserRepository } from '@/repositories/UserRepository';
import PopupNotifications from '@/components/global/popup/PopupNotifications.vue';
import { PopupNotificationOptions } from '@/interfaces/components/projectNew/PopupNotificationOptions';
import { checkForErrorsInFieldsAndReturnState, resetHelperState } from '@/helpers/FormValidationHelper';
import { FormValidationPayload } from '@/interfaces/general/FormValidationPayload.js';
import { ChecklistSegment } from '@/interfaces/components/configurator/ChecklistSegment';
import ColorModule from '@/components/global/popup/ColorModule.vue';

@Component({
    name: 'CalculatorProductView',
    components: {
        ChecklistSegmentModule,
        PriceModule,
        PopupNotifications,
        ColorModule,
    },
})
export default class CalculatorProductView extends Vue {
    @Action('configurator/getSingleProductForm') private getSingleProductForm!: (productFormId: string) => void;
    @Action('configurator/addActiveProductForm')
    private addActiveProductForm!: (activeProductForm: ActiveProductFormValueObject) => Promise<number | null>;
    @Getter('configurator/productForm') private productForm!: (productFormId: string) => ProductForm | null;
    @Action('configurator/calculatePrice') private calculatePrice!: ({
        activeProductFormValues,
        productFormId,
    }: PriceInputObject) => Promise<any>;
    @Mutation('configurator/resetActiveProductForms')
    private resetActiveProductForms!: () => void;
    @Action('configurator/updateActiveProductFormValue')
    private updateActiveProductFormValue!: ({
        pId,
        value,
        productFormId,
    }: {
        pId: string;
        value: ConfiguratorValue;
        productFormId: number;
    }) => void;
    @Getter('configurator/activeProductForm')
    private getProductFormValues!: (productFormId: number) => ActiveProductFormValueObject | null;
    private notifications: PopupNotificationOptions[] = [];
    private currentProductForm: ProductForm | null = null;
    private sellingPriceList: PriceItem[] = [];
    private purchasePriceList: PriceItem[] = [];
    private roltekPriceList: PriceItem[] = [];
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private activeProductFormId: null | number = null;
    private updateFunctions: UpdateFunctionEntry[] = [];
    private clientId: null | string = null;
    private pIdFieldErrors = [];
    private hasUnsavedData = false;
    private level: number = 2;
    private connection = null;
    private production = false;
    private get userGroup() {
        return this.$store.getters['jwtData/currentUserGroup'];
    }

    private debouncedPriceCalculation = debounce((productFormValues: ActiveProductFormValueObject) => {
        this.calculateConfiguratorPrice(productFormValues);
    }, 300);
    private debounceShowingChecklistErrors = debounce(() => {
        this.showChecklistErrors();
    }, 100);
    private debounceTriggerVisibilityFunctions = debounce(() => {
        this.triggerVisibilityFunctions();
    }, 100);
    private debounceTriggerOptionsFunctions = debounce(() => {
        this.triggerOptionsFunctions();
    }, 100);

    private switchLevel(level: number) {
        this.level = -1;

        this.$nextTick(() => {
            this.level = level;
        });
    }

    private clientSelected(clientId: string) {
        this.clientId = clientId;
        if (this.productFormValues != null) {
            this.calculateConfiguratorPrice(this.productFormValues);
        }
    }
    private get clientsPaymentTypeId() {
        if (this.client == null || this.client.paymentType == null) {
            return null;
        }

        return this.client.paymentType.id;
    }

    private get client() {
        if (this.clientId == null) {
            return null;
        }

        return ClientRepository.getById(this.clientId);
    }

    private get currencySymbol() {
        return UserRepository.getCurrentUsersCurrencySymbol();
    }

    private get functions() {
        return JsFunctionRepository.getAll();
    }

    private get checklistFieldErrorsExist() {
        return (
            this.pIdFieldErrors.length > 0 ||
            this.checklistErrors.some((checklistError: any) => checklistError.fieldType === 'abort')
        );
    }

    private get checklistSegments() {
        if (this.currentProductForm == null) {
            return [];
        }

        return this.currentProductForm.checklists.checklistSegments.filter(
            (checklistSegment: ChecklistSegment) => !checklistSegment.isLocked
        );
    }

    private get checklistErrors() {
        if (this.currentProductForm == null) {
            return [];
        }
        return this.currentProductForm.checklists.checklistSegments[0].checklistFields.filter(
            (checklistField: ChecklistField) => {
                return (
                    (checklistField.fieldType === 'warning' || checklistField.fieldType === 'abort') &&
                    this.productFormValues &&
                    determineFieldVisibility(checklistField.checklistVisibleFunction, this.productFormValues)
                );
            }
        );
    }

    private get productFormValues() {
        if (this.activeProductFormId == null) {
            return null;
        }

        return this.getProductFormValues(this.activeProductFormId);
    }

    private handleIsConnected(event: any) {
        this.connection = event;
    }

    private handleIsProduction(event: any) {
        this.production = event;
    }

    private triggerVisibilityFunctions() {
        EventBus.$emit(EventBusEvents.triggerVisibilityFunctions, {
            activeProductFormValues: this.productFormValues,
        });
    }

    private triggerOptionsFunctions() {
        EventBus.$emit(EventBusEvents.triggerOptionsFunctions, {
            activeProductFormValues: this.productFormValues,
            activeProductFormId: this.activeProductFormId,
        });
    }
    private goBack(): void {
        this.$router.go(-1);
        EventBus.$emit(EventBusEvents.calculatorPurchasePrice, []);
    }
    private async calculateConfiguratorPrice(productFormValues: ActiveProductFormValueObject) {
        this.loadingOverlay.start();

        this.pIdFieldErrors = [];
        const offerItemId = this.$route.params.offerItemId;

        if (this.currentProductForm == null) {
            this.sellingPriceList = [];
            return;
        }

        let sellingPriceList: PriceItem[] = [];

        try {
            const prices = await this.calculatePrice({
                activeProductFormValues: productFormValues,
                productFormId: this.currentProductForm.id,
                clientId: this.clientId,
                currencySymbol: this.currencySymbol,
                offerItemId,
                includePurchasePrice: true,
            });

            if (prices.purchasePrices) {
                this.purchasePriceList = prices.purchasePrices;
            }
            this.roltekPriceList = prices.roltekPrices;
            this.sellingPriceList = prices.sellingPrices;
            EventBus.$emit(EventBusEvents.calculatorPurchasePrice, prices);
        } catch (e) {
            if (Array.isArray(e)) {
                this.addNotification(this.$t('Error occured'), e[0] as string, 'error');
            }
            this.loadingOverlay.stop();
        }

        EventBus.$emit(EventBusEvents.calculatorProductData, {
            productFormValues,
            connection: this.connection,
            production: this.production,
            checklistFieldErrorsExist: this.checklistFieldErrorsExist,
            paymentTypeId: this.clientsPaymentTypeId,
            productFormId: this.currentProductForm.id,
        });

        this.loadingOverlay.stop();
    }
    private async getCurrentProductForm(): Promise<ProductForm | null> {
        const productFormId = String(this.$route.params.productFormId);

        try {
            await this.getSingleProductForm(productFormId);
        } catch (e) {
            this.addNotification(this.$t('Error occured'), this.$t('Error occured'), 'error');

            this.loadingOverlay.stop();
            this.$router.push({ name: RouteNames.error });
            return null;
        }

        return this.productForm(productFormId);
    }

    private updateUnsavedDataState({ state }: { state: boolean }) {
        this.hasUnsavedData = state;
    }

    private addNotification(title: string, description: string, errorType: 'error' | 'warning') {
        this.notifications.push({
            title: this.$t(title),
            description: this.$t(description),
            errorType,
        });
    }

    private setConfiguratorLevel() {
        const expertUserGroups = ['1', '2', '3'];

        if (expertUserGroups.includes(String(this.userGroup))) {
            this.level = 2;
        } else {
            this.level = 0;
        }
    }

    private validateFormFields() {
        if (!this.activeProductFormId && this.activeProductFormId !== 0) {
            return;
        }

        // Get visible fields and validate their values
        const formValues: FormValidationPayload[] = [];

        const rowValues = this.getProductFormValues(this.activeProductFormId);
        const flattenedFields = this.checklistSegments.map((segment) => segment.checklistFields).flat();

        formValues.push({
            rowKey: null,
            values: { ...rowValues },
            fieldSegments: flattenedFields as any,
        });

        const errorState = checkForErrorsInFieldsAndReturnState(formValues);

        const hasErrors = errorState.hasErrors;

        if (hasErrors) {
            this.showErrorState(errorState.errorDescription);
        }

        EventBus.$emit(EventBusEvents.formValidationFinished, hasErrors);
    }

    private showErrorState(description: string) {
        this.$notification.error({
            message: this.$t('Invalid characters detected in form fields:') as string,
            description: description as string,
        });
    }

    private updateColorValue(payload: any) {
        if (payload.activeProductFormId !== this.activeProductFormId) {
            return;
        }

        this.updateActiveProductFormValue({
            pId: payload.fieldToBeUpdatedPID,
            value: payload.fieldValue,
            productFormId: payload.activeProductFormId,
        });

        if (payload.selectedColorValue) {
            this.$nextTick(() => {
                this.updateActiveProductFormValue({
                    pId: payload.colorDropdownPID,
                    value: payload.selectedColorValue as string,
                    productFormId: payload.activeProductFormId,
                });
            });
        }
    }

    private async mounted() {
        resetHelperState();
        this.clientId = null;

        this.loadingOverlay.start();

        this.setConfiguratorLevel();
        EventBus.$on(EventBusEvents.changesInDataMade, this.updateUnsavedDataState);
        EventBus.$on(EventBusEvents.calculatorClientChosen, this.clientSelected);
        EventBus.$on(EventBusEvents.validateFormFields, this.validateFormFields);
        EventBus.$on(EventBusEvents.updateSystemColorValue, this.updateColorValue);

        if (this.functions.length <= 0) {
            try {
                await JsFunction.getAll();
            } catch (e) {
                return e;
            }
        }

        this.currentProductForm = await this.getCurrentProductForm();

        if (this.currentProductForm == null) {
            return;
        }

        this.activeProductFormId = await this.addActiveProductForm(
            await extractDefaultValues({
                productForm: this.currentProductForm,
                productId: this.$route.params.productId,
                clientId: this.$route.query.clientId as string | null,
                productSystemName: this.$route.query.productSystemName,
            })
        );

        this.updateFunctions = extractUpdateFunctions(this.currentProductForm);

        this.$emit(EventBusEvents.calculatorProductData, {
            productFormValues: this.productFormValues,
            connection: this.connection,
            production: this.connection,
            checklistFieldErrorsExist: this.checklistFieldErrorsExist,
            paymentTypeId: this.clientsPaymentTypeId,
            productFormId: this.currentProductForm.id,
        });

        this.loadingOverlay.stop();
    }

    private beforeDestroy() {
        EventBus.$emit(EventBusEvents.calculatorPurchasePrice, []);
        EventBus.$off(EventBusEvents.changesInDataMade, this.updateUnsavedDataState);
        EventBus.$off(EventBusEvents.calculatorClientChosen, this.clientSelected);
        EventBus.$off(EventBusEvents.validateFormFields, this.validateFormFields);
        EventBus.$off(EventBusEvents.updateSystemColorValue, this.updateColorValue);

        this.notifications = [];
        this.$notification.destroy();
        this.resetActiveProductForms();
    }

    @Watch(`productFormValues`, { deep: true, immediate: true })
    private onProductFormValueChange(productFormValues: ActiveProductFormValueObject) {
        this.$nextTick(() => {
            this.updateFunctions.forEach((entry: UpdateFunctionEntry) => {
                if (this.activeProductFormId == null) {
                    return;
                }
                const updatedValues: ConfiguratorValue[] = extractUpdateFunctionValues(
                    entry.updateFunctions,
                    entry.type,
                    productFormValues
                );
                if (updatedValues.length > 0) {
                    this.updateActiveProductFormValue({
                        pId: entry.id,
                        value: updatedValues[0],
                        productFormId: this.activeProductFormId,
                    });
                }
            });

            this.debounceTriggerVisibilityFunctions();
            this.debounceTriggerOptionsFunctions();
            // @ts-ignore
            this.debouncedPriceCalculation(productFormValues);
            this.debounceShowingChecklistErrors();
        });
    }

    private showChecklistErrors() {
        this.notifications = [];
        this.$notification.destroy();

        for (const checklistField of this.checklistErrors) {
            window.setTimeout(() => {
                if (checklistField.checklistStringField && checklistField.checklistStringField.defaultValue) {
                    switch (checklistField.fieldType) {
                        case 'abort':
                            this.addNotification(
                                'Error happened',
                                parseAndTransformStringWithDynamicValues(
                                    checklistField.checklistStringField.defaultValue,
                                    checklistField.checklistStringField.functions,
                                    this.productFormValues,
                                    'function'
                                ),
                                'error'
                            );

                            break;
                        case 'warning':
                            this.addNotification(
                                'Warning',
                                parseAndTransformStringWithDynamicValues(
                                    checklistField.checklistStringField.defaultValue,
                                    checklistField.checklistStringField.functions,
                                    this.productFormValues,
                                    'function'
                                ),
                                'warning'
                            );
                            break;
                    }
                }
            }, 50);
        }
    }

    @Watch('functions')
    private onFunctionsChange() {
        registerJsonLogicFunctions(this.functions);
    }
}
